<script setup lang="ts">
import { productUrl } from 'lib/routing'

interface Props {
  color: string
  sku: string
}

const props = defineProps<Props>()

const route = useRoute()

function getColor(color) {
  const colorMap = {
    darkpink: '#FF1493',
    lightpurple: '#BA55D3',
    lightred: '#FF6347',
    darkpurple: '#800080',
    lightbrown: '#CD853F',
    lightorange: '#FFA500',
    darkbrown: '#8B4513',
    darkyellow: '#FFD700',
  }
  return colorMap[color] ?? color
}
</script>

<template>
  <NuxtLink
    class="circle"
    :style="{ 'background-color': getColor(props.color) }"
    :to="{
      path: productUrl(props.sku),
      query: { ...route.query },
    }"
    :aria-label="`${$t(getColor(props.color))}`"
  />
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.circle {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  border: 1px solid #949494;
  &:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #949494;
  }
  &:focus {
    border: 1px solid #d8d7d7;
    outline: 1px solid #949494;
    outline-offset: 2px;
  }
}
</style>
