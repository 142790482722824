<script setup lang="ts">
import type { Product } from 'types/models/product'
import { useSwipe } from '@vueuse/core'

interface Props {
  quickShopState: 'uninitialized' | 'visible' | 'hidden'
  product: Product
  lazy?: boolean
  image: {
    url: string
    label: string
  }
  showPdpLink: boolean
}
defineProps<Props>()
const emit = defineEmits(['close'])

function onQuickShopClose() {
  emit('close')
}
const el = ref(null)
const { isSwiping, direction } = useSwipe(el)
watch(
  () => [isSwiping.value, direction.value],
  () => {
    if (isSwiping.value && direction.value === 'down') {
      onQuickShopClose()
    }
  },
)
</script>

<template>
  <div
    :class="[
      {
        popup: quickShopState === 'visible',
      },
    ]"
    @click.prevent="onQuickShopClose"
  />
  <div
    class="quick-shop" :class="[
      {
        'quick-shop--visible': quickShopState === 'visible',
        'quick-shop--hidden': quickShopState === 'hidden',
      },
    ]"
     ref="el"
  >
    <button class="close close--mobile" @click.prevent="onQuickShopClose">
      <span class="close__icon" />
    </button>
    <ImageResponsive
      provider="fastly"
      :src="image.url"
      :alt="image.label"
      :columns="[2, 4]"
      ratio="4:3"
      object-fit="contain"
      data-test="collection-product-image"
      :lazy="lazy"
      background-color="#FFFFFF"
      class="image"
    />
    <div class="edge" />
    <div class="quick-shop__content">
      <ProductSizeAndAddToCart :product="product" :show-pdp-link="showPdpLink" @close-quick-shop="onQuickShopClose" />
      <button class="close close--desktop" @click.prevent="onQuickShopClose">
        <span class="close__icon" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/edges';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/breakpoints';

.popup {
  background-color: var(--overlay-in-bg);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  // Must be that high to override the external styles of the chatbot
  z-index: 18;
  @media (min-width: $phablet) {
    display: none;
  }
}
.quick-shop {
  position: fixed;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0;
  transition: height 0.3s;
  pointer-events: none;
  // Must be that high to override the external styles of the chatbot
  z-index: 19;
  &--visible {
    animation: bounce-in-mobile 0.4s forwards;
    width: 100%;
    opacity: 1;
    pointer-events: all;
    @media (min-width: $phablet) {
      animation: bounce-in-desktop 0.4s forwards;
      opacity: 1;
    }
  }
  &--hidden {
    animation: hide-mobile 0.4s forwards;
    @media (min-width: $phablet) {
      animation: hide-desktop 0.4s forwards;
    }
  }
  &__content {
    padding: 2.4rem 2.4rem 0.8rem;
    background-color: var(--gray-light);
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    position: relative;
    top: -0.7rem;
    &:deep(.variant-dropdown) {
      border-bottom: 1px solid var(--gray-light);
    }
    @media (min-width: $phablet) {
      min-height: unset;
      height: calc(100% - 15px);
      top: unset;
    }
  }
  @media (min-width: $phablet) {
    position: absolute;
    top: unset;
    bottom: 0;
    width: 100%;
    z-index: unset;
  }
}
.edge {
  position: relative;
  top: -0.7rem;
  left: 0;
  width: 100%;
  height: 1.5rem;
  background-color: var(--gray-light);
  border: none;
  @include teeth;
  @media (min-width: $phablet) {
    top: 2px;
  }
}

.close {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
  &__icon {
    mask: url('/icons/close.svg') no-repeat center / contain;
    width: 1rem;
    height: 1rem;
    background-color: var(--black);
  }
  &--mobile {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    // Must be that high to override the external styles of the chatbot
    z-index: 20;
    @media (min-width: $phablet) {
      display: none;
    }
  }
  &--desktop {
    background-color: var(--gray);
    border-radius: 50%;
    display: none;
    @media (min-width: $phablet) {
      display: flex;
    }
  }
}

.image {
  @include teeth;
  background-color: var(--white);
  display: block;
  @media (min-width: $phablet) {
    display: none;
  }
}

@keyframes bounce-in-mobile {
  0% {
    height: 0;
  }
  50% {
    height: 85vh;
  }
  100% {
    height: 82vh;
  }
}

@keyframes bounce-in-desktop {
  0% {
    height: 0;
  }
  50% {
    height: 24rem;
  }
  100% {
    height: 21.5rem;
  }
}

@keyframes hide-mobile {
  0% {
    height: 82vh;
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    height: 0rem;
    opacity: 0;
  }
}

@keyframes hide-desktop {
  0% {
    height: 21.5rem;
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    height: 0rem;
    opacity: 0;
  }
}
</style>
